import { Login, Home, Dashboard, AddStudent, AllGrade, AddStdMo } from './pages';
import './global.scss';
import './i18n';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from "./context/AuthContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";


function App() {

  const { currentUser } = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    return currentUser ? (children) : <Navigate to="/login" />
  };

  const LoginRequireAuth = ({ children }) => {
    return currentUser ? <Navigate to="/" /> : (children);
  };

  const { i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    changeLanguage('ar');
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="login" element={<LoginRequireAuth><Login /></LoginRequireAuth>} />
          </Route>
          <Route path="login" element={<LoginRequireAuth><Login /></LoginRequireAuth>} />
          <Route path="/home">
            <Route index element={<Home />} />
          </Route>
          <Route path="/dashboard">
            <Route index element={<RequireAuth><Dashboard /></RequireAuth>} />
          </Route>
          <Route path="/add_student">
            <Route index element={<RequireAuth><AddStudent /></RequireAuth>} />
          </Route>
          <Route path="/allGrade">
            <Route index element={<RequireAuth><AllGrade /></RequireAuth>} />
          </Route>
          <Route path="/add_student_teslaSchool">
            <Route index element={<RequireAuth><AddStdMo /></RequireAuth>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
