import React, { useState, useEffect } from 'react';
import SchoolIdContext from './SchoolIdContext';
import { auth, db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const SchoolIdProvider = ({ children }) => {
  const [data, setData] = useState(null);

  const fetchData = async (cuid) => {
    let list = [];
    try {
      const schoolsCollection = collection(db, "schools");
      const q = query(schoolsCollection, where("schoolAccount", "==", cuid));
      const querySnapshot = await getDocs(q);
      setData(querySnapshot.docs[0]);
      // console.log(data.data()['sign'])
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchData(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <SchoolIdContext.Provider value={data}>
      {children}
    </SchoolIdContext.Provider>
  );
};

export default SchoolIdProvider;
