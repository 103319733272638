// utils.js for add Student
export function generateRandomString(length, characters) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function generateRandomEmail(sign) {
    const domain = `${sign}.school`;
    const emailCharacters = "abcdefghijklmnopqrstuvwxyz123456789";
    const localPartLength = 10;
    const localPart = generateRandomString(localPartLength, emailCharacters);
    return `${localPart}@${domain}`;
}

export function generateRandomPassword() {
    const passwordCharacters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const passwordLength = 12;
    return generateRandomString(passwordLength, passwordCharacters);
}