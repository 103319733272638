import {React, useContext} from 'react';
import './home.scss';
import { ReactComponent as Logo } from '../../assets/logos/logoWhite.svg';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";

function Home() {
  const { currentUser } = useContext(AuthContext)
  const navigate = useNavigate();
  function navigateToLogin(){
    if(currentUser){
      navigate('/dashboard');
    }else{
      navigate('/login');
    }
    
  }
  return (
    <div className='home'>
      <Logo />
      <span onClick={navigateToLogin}>تسجيل الدخول</span>
    </div>
  )
}

export default Home;