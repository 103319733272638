import React, { useState, useRef, useEffect } from 'react';
import './addStudent.scss';
import { Sidebar, Navbar } from '../../../components/index';
import { useTranslation } from 'react-i18next';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import 'react-datepicker/dist/react-datepicker.css';
import { db, auth, storage } from "../../../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useSchoolId } from '../../../context/schoolIdContect/SchoolIdContext';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { generateRandomEmail, generateRandomPassword } from './utils'; // Adjust the path as necessary
import axios from 'axios';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AddStudent() {
  const fileInputRef = useRef(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const { t } = useTranslation();
  const schoolId = useSchoolId();
  const [data, setData] = useState({
    'gender' : '',
    'Governorate' : '',
    'classSection' : '',
    'educationalLevel' : '',
  });
  const [emailDomain, setEmailDomain] = useState('erido');

  // useEffect(() => {

  //   imagePreviewUrl && uploudImage();
  // }, [imagePreviewUrl]);

  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
    console.log(data);
  };

  const handleInputChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
    console.log(data);
  }

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      setImagePreviewUrl(imageUrl);
      setImageFile(event.target.files[0])
      // console.log(event.target.files[0]);
      // console.log(imagePreviewUrl);
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const removeImage = () => {
    setImagePreviewUrl(null);
  };

  const handleAdd = async (e) => {
    e.preventDefault()
    try {
      if (!schoolId) return;

      const handleGenerateEmail = () => {
        return generateRandomEmail(emailDomain);
      };
      const email = handleGenerateEmail();
      const password = generateRandomPassword();
      console.log(email, password);
      const createUser = async (email, password) => {
        try {
          const response = await axios.post('https://erido.co/api/create_user.php', {
            email: email,
            password: password
          });

          console.log(response.data);
          // Handle the response data here
        } catch (error) {
          console.error('There was an error!', error);
        }
      };

      await createUser(email, password).then(async () => {
        await setDoc(doc(db, "schools", schoolId.id, "students", email), {
          ...data,
          email: email,
          password: password,
          time: serverTimestamp(),
        })
          .then(() => {
            console.log("Document successfully written!");
            toast.success(t('addStudenDone'));
            e.target.reset();
            removeImage();
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
            toast.error(t('errorWhenCreateUser'));
          });
      });
    } catch (err) {
      console.log(err);
    }

  }

  useEffect(() => {
    const uploudImage = async () => {
      const imageName = new Date().getTime() + imageFile.name;
      const storageRef = ref(storage, `schools/${schoolId.data()['sign']}/students/profileImages/${imageName}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
            setData({ ...data, profileImage: downloadURL })
          });
        }
      );
    }
    imageFile && uploudImage();
  }, [imageFile]);
  

  return (
    <div className='addStudent'>
      <div className="addStudentContainer">
        <Navbar />
        <h1 className='title'>{t("addStudentForm")}</h1>
        <div className="formContainer">
          <form onSubmit={handleAdd}>
            <div className="section">
              <div className="inputSection">
                <h3 className='title'>{t("educationalLevel")}</h3>
                <select value={data.educationalLevel} onChange={handleChange} id='educationalLevel'>
                  <option value="non">{t("selectLevel")}</option>
                  <option value="classOne">{t("classOne")}</option>
                  <option value="classSecond">{t("classSecond")}</option>
                  <option value="classThird">{t("classThird")}</option>
                  <option value="classFourth">{t("classFourth")}</option>
                  <option value="classFifth">{t("classFifth")}</option>
                  <option value="classSixth">{t("classSixth")}</option>
                  <option value="classOneM">{t("classOneM")}</option>
                  <option value="classSecondM">{t("classSecondM")}</option>
                  <option value="classThirdM">{t("classThirdM")}</option>
                  <option value="classFourthMS">{t("classFourthMS")}</option>
                  <option value="classFifthMS">{t("classFifthMS")}</option>
                  <option value="classSixthMS">{t("classSixthMS")}</option>
                  <option value="classFourthML">{t("classFourthML")}</option>
                  <option value="classFifthML">{t("classFifthML")}</option>
                  <option value="classSixthML">{t("classSixthML")}</option>
                </select>
              </div>

              <div className="inputSection">
                <h3 className='title'>{t("classSection")}</h3>
                <select value={data.classSection} onChange={handleChange} id='classSection'>
                  <option value="non">{t("selectclassSection")}</option>
                </select>
              </div>

              <div className="inputSection">
                <h3 className='title'>{t("Governorate")}</h3>
                <select value={data.Governorate} onChange={handleChange} id='Governorate'>
                  <option value="non">{t("selectGovernorate")}</option>
                  <option value="Baghdad">بغداد</option>
                  <option value="Erbil">أربيل</option>
                  <option value="Anbar">الأنبار</option>
                  <option value="Babylon">بابل</option>
                  <option value="Basra">البصرة</option>
                  <option value="Dohuk">دهوك</option>
                  <option value="Al-Qadisiyah">القادسية</option>
                  <option value="Diyala">ديالى</option>
                  <option value="Dhi Qar">ذي قار</option>
                  <option value="Sulaymaniyah">السليمانية</option>
                  <option value="Salahuddin">صلاح الدين</option>
                  <option value="Kirkuk">كركوك</option>
                  <option value="Karbala">كربلاء</option>
                  <option value="mothana">المثنى</option>
                  <option value="Maysan">ميسان</option>
                  <option value="Najaf">النجف</option>
                  <option value="Nineveh">نينوى</option>
                  <option value="Wasit">واسط</option>
                </select>
              </div>

              <div className="inputSection">
                <h3 className='title'>{t("adress")}</h3>
                <input type="adress" placeholder={t("adress")} className='adress' name='adress' id='adress' onChange={handleInputChange} />
              </div>

              <div className="inputSection">
                <h3 className='title'>{t("totalinstallment")}</h3>
                <input type="text" placeholder={t("totalinstallment")} className='totalinstallment' name='totalinstallment' id='totalinstallment' onChange={handleInputChange} />
              </div>

              <div className="btns">
                <button type='reset' className='clear' onClick={removeImage}>{t("delete")}</button>
                <button type='submit' className='submit'>{t("addStudentBtn")}</button>
              </div>
            </div>

            <div className="section">
              <div className="avatar" onClick={handleAvatarClick}>
                {!imagePreviewUrl && <PersonAddAlt1Icon className='icon' />}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
                {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" />}
              </div>
              <div className="inputSection">
                <h3 className='title'>{t("studentName")}</h3>
                <input type="text" placeholder={t("studentName")} className='name' name='name' id='name' onChange={handleInputChange} required />
              </div>
              <div className="inputSection">
                <h3 className='title'>{t("studentDadName")}</h3>
                <input type="text" placeholder={t("studentDadName")} className='dadName' name='dadName' id='dadName' onChange={handleInputChange} required />
              </div>
              <div className="inputSection">
                <h3 className='title'>{t("grandfatherName")}</h3>
                <input type="text" placeholder={t("grandfatherName")} className='grandfatherName' name='grandfatherName' id='grandfatherName' onChange={handleInputChange} required />
              </div>
              <div className="inputSection">
                <h3 className='title'>{t("studentFatherPhone")}</h3>
                <input type="tel" placeholder={t("studentFatherPhone")} className='studentFatherPhone' name='studentFatherPhone' id='studentFatherPhone' onChange={handleInputChange} required />
              </div>
              <div className="inputSection">
                <h3 className='title'>{t("dateOfBirth")}</h3>
                <input type="date" className='dateOfBirth' name='dateOfBirth' id='dateOfBirth' onChange={handleInputChange} required />
              </div>
              <div className="inputSection">
                <h3 className='title'>{t("gender")}</h3>
                <select value={data.gender} onChange={handleChange} id='gender'>
                  <option value="non">{t("selectGender")}</option>
                  <option value="male">{t("male")}</option>
                  <option value="female">{t("female")}</option>
                </select>
              </div>
            </div>
          </form>


        </div>
      </div>
      <Sidebar curentPage='addStudent' />
      <ToastContainer position="bottom-left" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
    </div>
  )
}

export default AddStudent;