import { React } from 'react';
import { useContext, useState, useRef } from "react";
import './navbar.scss';
import { useTranslation } from 'react-i18next';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { getAuth, signOut } from "firebase/auth";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";


function Navbar() {

  const auth = getAuth();
  const { dispatch } = useContext(AuthContext)
  const navigate = useNavigate();
  
  

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    signOut(auth).then(() => {
      const user = null;
      dispatch({ type: "LOGIN" ,payload: null});
      navigate("/login")
    }).catch((error) => {
      // An error happened.
    });
  }
  return (
    <div className='navbar'>
      <div className="user">
        <div className="info">
          <p className='name'>محمد علي</p>
          <p className='type'>مدير المدرسة</p>
        </div>

        <Box className='box' sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} >
          <Tooltip title={t("accountSettings")}>
            <IconButton
            
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <img src="https://profiles.rice.edu/sites/g/files/bxs3881/files/2023-06/tourweb.jpg" alt="profileImage" />
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          
        >
          <MenuItem onClick={handleClose}>
            <img src="https://profiles.rice.edu/sites/g/files/bxs3881/files/2023-06/tourweb.jpg" alt="profileImage" style={{ width: 50, borderRadius: 100, marginRight: 10 }} /> {t("myAccount")}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {t("settings")}
          </MenuItem>
          <MenuItem onClick={logOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t("logout")}
          </MenuItem>
          
        </Menu>
      </div>
      <div className="searchBox">
        <SearchOutlinedIcon className='icon' />
        <p>{t("searchStudentOrTeacher")}</p>
      </div>

    </div>
  )
}

export default Navbar;