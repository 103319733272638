import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import { Sidebar, Navbar } from '../../components';
import { SchoolAnalytics, Student, Installments } from '../../components/dashboard';
import { db } from "../../firebase";
import { useSchoolId } from '../../context/schoolIdContect/SchoolIdContext';
import { doc, getDoc } from "firebase/firestore";



// const useGetData = () => {
  
//   const [data, setData] = useState(null);
//   if(schoolId){
//     console.log(schoolId.id);
//   }
  

//   const feachData = async (scId) => {
//     if (!scId) return;
//     try {
//       if (schoolId) {
//         const docRef = doc(db, "schools", scId);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           setData(docSnap.data())

//         } else {
//           // docSnap.data() will be undefined in this case
//           console.log("No such document!");
//         }
//       }


//     } catch (err) {
//       console.log(err);
//     }

//   }

//   // useEffect(() => {
//   //   if (schoolId) {
//   //     feachData(schoolId.id);
//   //   }
//   // }, [schoolId]);

//   // console.log(data);
//   // return data;
// }


function Dashboard() {
  // const data = useGetData();
  const schoolId = useSchoolId();


  return (
    <div className='dashboard'>
      <div className="dashboardContainer">
        <Navbar />
        <SchoolAnalytics school={!schoolId ? 'مدرسة' : schoolId.data()['schoolName']} />
        <div className="dataVisualize">
          <Installments />
          <Student />
        </div>

      </div>
      <Sidebar curentPage='dashboard' />
    </div>
  )
}

export default Dashboard;