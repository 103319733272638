import React, { PureComponent } from 'react';
import './installments.scss';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

const data = [
  {
    name: 'week 1',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'week 2',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'week 3',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'week 4',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'week 5',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
];



function Installments() {
  const { t } = useTranslation();
  return (
    <div className='installments'>
      <div className="title">
        <div className="info">
          <div className="secion">
            <p>تم التسديد</p>
            <div className="circle" style={{backgroundColor: '#8884d8'}}></div>
          </div>
          <div className="secion">
            <p>الباقي</p>
            <div className="circle" style={{backgroundColor: '#5DCEFF'}}></div>
          </div>
        </div>
        <h3>{t("installments")}</h3>
      </div>
      <div className="chart">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
          <Area type="monotone" dataKey="amt" stackId="1" stroke="#5DCEFF" fill="#5DCEFF" />
        </AreaChart>
      </ResponsiveContainer>
      </div>
    </div>
  )
}

export default Installments;