import React from 'react';
import './schoolAnalytics.scss';
import { useTranslation } from 'react-i18next';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';

function SchoolAnalytics(props) {
  const { t } = useTranslation();
  return (
    <div className='schoolAnalytics'>
      <h1>{props.school}</h1>

      <div className='sections'>

      <div className="section" style={{backgroundColor: "#EBFDEF"}}>
          <PriceChangeOutlinedIcon className='icon' style={{color: "#6BD883"}}/>
          <div className="info">
            <p>{t("installments")}</p>
            <span>IQD 250m</span>
          </div>
        </div>

        <div className="section" style={{backgroundColor: "#FFEFE7"}}>
          <SupervisorAccountOutlinedIcon className='icon' style={{color: "#FF9D6B"}}/>
          <div className="info">
            <p>{t("parents")}</p>
            <span>3,214</span>
          </div>
        </div>

        <div className="section" style={{backgroundColor: "#E5F5FF"}}>
          <PortraitOutlinedIcon className='icon' style={{color: "#71C8FF"}}/>
          <div className="info">
            <p>{t("teachers")}</p>
            <span>120</span>
          </div>
        </div>


        <div className="section" style={{backgroundColor: "#F1E7FF"}}>
          <Groups2OutlinedIcon className='icon' style={{color: "#AE7BF5"}}/>
          <div className="info">
            <p>{t("students")}</p>
            <span>5,320</span>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SchoolAnalytics;