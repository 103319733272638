import * as React from 'react';
import './allGrade.scss';
import { Sidebar, Navbar } from '../../../components/index';
import { useTranslation } from 'react-i18next';
import { DataGrid, arSD } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import { db } from "../../../firebase";
import { useSchoolId } from '../../../context/schoolIdContect/SchoolIdContext';
import { addDoc, collection, serverTimestamp, onSnapshot } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AllGrade() {
    const schoolId = useSchoolId();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [grades, setGrades] = React.useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addGrade = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const grade = formJson.grade;
        console.log(grade);


        if (!schoolId) return;

        await addDoc(collection(db, "schools", schoolId.id, "grades" ), {
            grade: grade,
            time: serverTimestamp(),
        }).then(() => {
            handleClose();
            console.log("Document successfully written!");
            toast.success('تم اضافة المرحلة بنجاح');
        }).catch((error) => {
            console.error("Error writing document: ", error);
            toast.error(t('لم تتم اضافة المرحلة الداسية'));
        });
    }

    React.useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "schools", schoolId.id, "grades"), (snapshot) => {
            const gradesData = snapshot.docs.map(doc => ({
                id: doc.id, // Important: DataGrid requires a unique 'id' field for each row
                ...doc.data()
            }));
            setGrades(gradesData);
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, []);

    const columns = [
        { field: 'grade', headerName: 'المرحلة الدراسية', flex: 5,  cellClassName: 'field'},
        { field: 'id', headerName: 'كود المرحلة', flex: 3,  cellClassName: 'field'},
        {
            field: 'status',
            headerName: 'تعديل',
            flex: 1,
            cellClassName: 'field',
            renderCell: (params) => (
                <IconButton
                    onClick={() => {}}
                    aria-label="edit"
                    className='icon'
                >
                    <EditIcon />
                </IconButton>
            ),
        },
        // Define other columns based on your Firestore document structure
        
        // Add more fields as needed
    ];
    
    const columns2 = [
        { field: 'id', headerName: 'ID', cellClassName: 'field' },
        { field: 'firstName', headerName: 'First name', flex: 2, cellClassName: 'field' },
        { field: 'lastName', headerName: 'Last name', flex: 2, cellClassName: 'field' },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            flex: 1,
            cellClassName: 'field',
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            flex: 3,
            cellClassName: 'field',
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            cellClassName: 'field',
            renderCell: (params) => (
                <IconButton
                    onClick={() => {}}
                    aria-label="edit"
                    className='icon'
                >
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    const theme = createTheme({
        direction: 'rtl', // Right to left
    });

    const handleCellClick = (params) => {
        // The params object contains information about the cell clicked

        // Access specific data from the cell
        const cellValue = params.value;
        const cellField = params.field;
        const rowData = params.row;
        // console.log(`Cell value: ${cellValue}, Field: ${cellField}`, `Row data:`, rowData);
        console.log(rowData['id']);
    };

    const myCustomLocaleText = {
        // Define your custom text here
        noRowsLabel: 'لا توجد مراحل دراسية',
        columnMenuLabel: 'قائمة العمود',
        columnsPanelTextFieldLabel: 'البحث عن عمود',
        pageSize: ' حجم الصفحة: عدد الصفوف المعروضة في كل صفحة',
        columns: 'الأعمدة: تعريفات الأعمدة للجدول، بما في ذلك العنوان، العرض، وغيرها من الإعدادات',
        // ... any other text you want to customize
    };

    return (
        <div className='allGrade'>
            <ToastContainer position="bottom-left" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
            <div className="allGradeContainer">
                <Navbar />
                <div className="top">

                    <div className="addGradeSection">
                        <p>اضافة مرحلة دراسية</p>
                        <IconButton
                            onClick={handleClickOpen}
                            aria-label="edit"
                            className='icon'
                        >
                            <AddIcon />
                        </IconButton>
                    </div>
                    <h1 className='title'>{t("allGrade")}</h1>

                </div>
                <div className="addGrade">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <div dir="rtl" style={{ height: '100%', width: '100%' }}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <DataGrid
                                        localeText={arSD.components.MuiDataGrid.defaultProps.localeText}
                                        rows={grades}
                                        columns={columns}
                                        onCellClick={
                                            handleCellClick
                                        }
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 10 },
                                            },
                                        }}
                                        pageSizeOptions={[10, 20]}
                                        disableColumnSelector
                                        disableRowSelectionOnClick
                                        disableDensitySelector
                                        disableEval
                                        disableVirtualization
                                    />
                                </div>
                            </div>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
            </div>
            <Sidebar curentPage='allGrade' />
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { textAlign: 'right' },
                    component: 'form',
                    onSubmit: (event) => {
                        addGrade(event);
                    },
                }}
            >
                <DialogTitle>اضافة مرحلة دراسية جديدة</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        اكتب اسم المرحلة الدراسية في الخانة التالية واضغط على اضافة المرحلة
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="grade"
                        name="grade"
                        label="اسم المرحلة الدراسية"
                        type="name"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>الغاء</Button>
                    <Button type="submit">اضافة المرحلة</Button>
                </DialogActions>
            </Dialog>
            
        </div>
    )
}

export default AllGrade;