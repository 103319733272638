import React from 'react';
import './login.scss';
import { useContext, useState, useRef } from "react";
import { ReactComponent as Logo } from '../../assets/logos/eridologo.svg';
import { useTranslation } from 'react-i18next';
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';



function Login() {

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const {dispatch} = useContext(AuthContext)

  const handleLogin = (e) => {
    e.preventDefault();
    // setLoading(true)

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed In 
        const user = userCredential.user.uid;
        const userToken = userCredential.user.refreshToken;
        dispatch({ type: "LOGIN", payload: {user, userToken}});
        navigate("/dashboard")
        // ...
        // setLoading(false)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        setError(true);
        if(errorCode == "auth/invalid-credential"){
          setErrorText(t('loginError'))
        }else{
          setErrorText(t('loginErrerF'))
        }
        setLoading(false)
        // ..
      });
  }

  const { t } = useTranslation();

  const handleKeyDown = (event, nextInputRef) => {
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevent the default form submit behavior
      nextInputRef.current.focus();  // Focus the next input
    }
  };

  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);
  return (
    <div className='loginpage'>
      <div className="navbar">
        <h1>{t('login')}</h1>
      </div>

      <div className="Container">
        < Logo className='logo' />
        <div className="loginBox">
          <h3>{t('emailAndPass')}</h3>
          <form onSubmit={!isLoading ? handleLogin : null}>
            <input type="email"ref={firstInputRef} onKeyDown={(e) => handleKeyDown(e, secondInputRef)} onChange={e => setEmail(e.target.value)}  placeholder={t('email')} id="email" name="email" required />
            <input type="password" ref={secondInputRef} onChange={e => setPassword(e.target.value)} id="password" placeholder={t('pass')} name="password" minLength="8" required />
            <button type="submit">{isLoading ? <HourglassBottomOutlinedIcon className='loadingIcon' color='#FFF'/> : t('login')}</button>
          </form>
          {error && <p className="error">{errorText}</p>}
          
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default Login;