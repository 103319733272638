import React, { useState, useRef } from "react";
import "./addStdMo.css";
import schoolLogo from "../../../assets/images/mohandsenlogo.PNG";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import ReactToPrint from "react-to-print";

function AddStdMo() {
  const divRef = useRef();
  const stdPhoto = useRef(null);
  const [stdPhotoUrl, setStdPhotoUrl] = useState(null);
  const [stdGander, setStdGander] = useState("ذكر");
  const [stdClass, setStdClass] = useState("");
  const allClass = [
    "الاول متوسط",
    "الثاني متوسط",
    "الثالث متوسط",
    "الرابع العلمي",
    "الرابع الادبي",
    "الخامس العلمي",
    "الخامس الادبي",
    "السادس العلمي",
    "السادس الادبي",
  ];
  const [classType, setClassType] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const schoolBoys = ["ثانوية تسلا للبنين"];
  const schoolGirls = ["ثانوية تسلا للبنات"];
  const instituteBoys = ["معهد المهندسين للبنين"];
  const instituteGirls = ["معهد المهندسين للبنات"];

  const componentRef = useRef();

  const handleClick = () => {
    stdPhoto.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setStdPhotoUrl(imageUrl);
      console.log("Selected file:", file);
    }
  };

  const [selectedArabic, setselectedArabic] = useState([]);
  const [selectedEnglish, setselectedEnglish] = useState([]);
  const [selectedMath, setselectedMath] = useState([]);
  const [selectedChemistry, setselectedChemistry] = useState([]);
  const [selectedBiology, setselectedBiology] = useState([]);
  const [selectedPhysics, setselectedPhysics] = useState([]);

  const arabics = [
    "الاستاذ علاء فاضل",
    "الاستاذ خضر الضاهر",
    "الاستاذ محمد هادي",
    "الاستاذ يوسف مناتي",
    "لاستاذ عماد عبدالستار العبيدي",
  ];
  const english = [
    "الاستاذ اسعد حطاب",
    "الاستاذ مؤيد الغرباوي",
    "الاستاذ علاء السعداوي",
    "الاستاذ سجود حيدر",
    "الاستاذ رافد سامي",
    "الاستاذ حسين السعيدي",
  ];
  const math = [
    "الاستاذ خالد جمال",
    "الاستاذ حسن الشمري",
    "الاستاذ حسين سلمان",
    "الاستاذ انمار القيسي",
    "الاستاذ رافد سامي",
    "الاستاذ حيدر عبد الائمة",
  ];
  const chemistry = [
    "الاستاذ هاشم الغرباوي",
    "الاستاذ حسين غازي",
    "الاستاذ كاظم سعيد",
  ];

  const physics = ["الاستاذ جواد الزبيدي"];

  const biology = ["الاستاذ عماد الطائي", "الاستاذ احمد حسان"];

  const handleChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setselectedArabic([...selectedArabic, value]);
    } else {
      setselectedArabic(selectedArabic.filter((option) => option !== value));
    }
  };
  const handleChangeEnglish = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setselectedEnglish([...selectedEnglish, value]);
    } else {
      setselectedEnglish(selectedEnglish.filter((option) => option !== value));
    }
  };
  const handleChangeMath = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setselectedMath([...selectedMath, value]);
    } else {
      setselectedMath(selectedMath.filter((option) => option !== value));
    }
  };

  const handleChangeChemistry = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setselectedChemistry([...selectedChemistry, value]);
    } else {
      setselectedChemistry(
        selectedChemistry.filter((option) => option !== value)
      );
    }
  };

  const handleChangeBiology = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setselectedBiology([...selectedBiology, value]);
    } else {
      setselectedBiology(selectedBiology.filter((option) => option !== value));
    }
  };

  const handleChangePhysics = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setselectedPhysics([...selectedPhysics, value]);
    } else {
      setselectedPhysics(selectedPhysics.filter((option) => option !== value));
    }
  };

  const deleteAll = () => {
    setStdPhotoUrl(null);
    setStdGander("ذكر");
    setStdClass("");
    setClassType("");
    setSelectedSchool("");
    setselectedArabic([]);
    setselectedEnglish([]);
    setselectedMath([]);
    setselectedChemistry([]);
    setselectedBiology([]);
    setselectedPhysics([]);
  };

  return (
    <div className="addStdView" ref={componentRef}>
      <div className="addStdView_nav">
        <img src={schoolLogo} alt="" />
        <div></div>
        <p>اسـتـمـارة تـسـجـيـل الـطـلاب</p>
      </div>
      <div className="addStdView_body">
        <div className="formContainer">
          <div className="addStdView_stdInfo">
            <h4>معلومات الطالب</h4>
            <p>صورة الطالب</p>

            <div className="stdPhoto">
              <div className="uploudPhoto" onClick={handleClick}>
                <input
                  type="file"
                  accept="image/*"
                  ref={stdPhoto}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {stdPhotoUrl === null ? (
                  <AddAPhotoOutlinedIcon style={{ fontSize: "30px" }} />
                ) : (
                  <img
                    className="stdPhoto"
                    src={stdPhotoUrl}
                    alt="Student Photo"
                  />
                )}
              </div>
            </div>

            <p>الاسم الكامل</p>
            <div className="stdInfo_name">
              <input type="text" placeholder="اسم الطالب" />
              <input type="text" placeholder="اسم الاب" />
              <input type="text" placeholder="اسم الجد" />
            </div>

            <p>رقم الهاتف</p>
            <div className="stdInfo_name">
              <input type="text" placeholder="رقم الطالب" />
              <input type="text" placeholder="رقم ولي الامر" />
            </div>

            <p>عنوان السكن</p>
            <div className="stdInfo_name">
              <input type="text" placeholder="تفاصيل عنوان السكن" />
            </div>

            <p>الجنس والميلاد</p>
            <div className="stdInfo_name">
              <select
                name=""
                id=""
                value={stdGander}
                onChange={(e) => {
                  setStdGander(e.target.value);
                }}
              >
                {/* <option value="">الجنس</option> */}
                {["ذكر", "انثى"].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <input
                className="dateStd"
                type="text"
                placeholder="تولد الطالب يوم-شهر-سنة"
              />
            </div>
            <p></p>
            <p></p>
            <h4>معلومات المدرسة او المعهد</h4>
            <p>المدرسة السابقة والمرحلة الدراسية</p>
            <div className="stdInfo_name">
              <input
                className="dateStd"
                type="text"
                placeholder="اسم المدرسة السابقة"
              />
              <select
                name=""
                id=""
                value={stdClass}
                onChange={(e) => {
                  setStdClass(e.target.value);
                }}
              >
                <option value="">المرحلة الدراسية</option>
                {allClass.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <p>المدرسة او المعهد</p>
            <div className="stdInfo_name">
              <select
                name=""
                id=""
                value={stdClass}
                onChange={(e) => {
                  setStdClass(e.target.value);
                }}
              >
                {/* <option value="">المدرسة والمعهد</option> */}
                {stdGander === "ذكر"
                  ? ["ثانوية تسلا للبنين", "معهد المهندسين للبنبن"].map(
                      (item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    )
                  : ["ثانوية تسلا للبنات", "معهد المهندسين للبنات"].map(
                      (item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    )}
              </select>
            </div>
            <p></p>
            <p></p>
            <h4>اسماء اساتذة معهد المهندسين وثانوية تسلا</h4>
            <div className="teachersForm">
              <form>
                <p>الرياضيات</p>
                {math.map((option, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="checkbox"
                        value={option}
                        onChange={handleChangeMath}
                      />
                      {option}
                    </label>
                  </div>
                ))}
              </form>
              <form>
                <p>اللغة الانكليزية</p>
                {english.map((option, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="checkbox"
                        value={option}
                        onChange={handleChangeEnglish}
                      />
                      {option}
                    </label>
                  </div>
                ))}
              </form>
              <form>
                <p>اللغة العربية</p>
                {arabics.map((option, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="checkbox"
                        value={option}
                        onChange={handleChange}
                      />
                      {option}
                    </label>
                  </div>
                ))}
              </form>
            </div>

            <div className="teachersForm">
              <form>
                <p>الاحياء</p>
                {physics.map((option, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="checkbox"
                        value={option}
                        onChange={handleChangePhysics}
                      />
                      {option}
                    </label>
                  </div>
                ))}
              </form>
              <form>
                <p>الاحياء</p>
                {biology.map((option, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="checkbox"
                        value={option}
                        onChange={handleChangeBiology}
                      />
                      {option}
                    </label>
                  </div>
                ))}
              </form>
              <form>
                <p>الكيمياء</p>
                {chemistry.map((option, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="checkbox"
                        value={option}
                        onChange={handleChangeChemistry}
                      />
                      {option}
                    </label>
                  </div>
                ))}
              </form>
            </div>
          </div>
        </div>
        <div className="btnActions">
          <button className="saveBtn">حفظ</button>
          <ReactToPrint
            trigger={() => <button className="printBtn">طباعة</button>}
            content={() => componentRef.current}
            pageStyle="@media print { body { -webkit-print-color-adjust: exact; } }"
          />
          {/* <button className="deleteBtn" onClick={deleteAll}>
            حذف
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default AddStdMo;
