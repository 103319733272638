import React from 'react';
import './sidebar.scss';
import logo from '../../assets/logos/second_logo.svg';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



function Sidebar(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function navigateToDashboard(){
    navigate('/dashboard');
  }
  function navigateToAddUser(){
    navigate('/add_student');
  }
  function navigateToAllGrde(){
    navigate('/allGrade');
  }

  return (
    <div className='sidebar'>
      <img src={logo} alt="Logo" className='logo' />
      <div className="sections">
        <div className={props.curentPage == "dashboard" ? "secstionActive" : "section" } onClick={navigateToDashboard}>
          <h3>{t('homePage')}</h3>
          <OtherHousesOutlinedIcon className='icon' />
        </div>
        <div className={props.curentPage == "allGrade" ? "secstionActive" : "section" } onClick={navigateToAllGrde}>
          <h3>{t('class')}</h3>
          <LocalLibraryOutlinedIcon className='icon' />
        </div>
        <div className="section">
          <h3>{t('students')}</h3>
          <SchoolOutlinedIcon className='icon' />
        </div>
        <div className="section">
          <h3>{t('teachers')}</h3>
          <GroupsOutlinedIcon className='icon' />
        </div>
        <div className="section">
          <h3>{t('parents')}</h3>
          <WcOutlinedIcon className='icon' />
        </div>
        <div className="section">
          <h3>{t('studySchedule')}</h3>
          <StickyNote2OutlinedIcon className='icon' />
        </div>
        <div className="section">
          <h3>{t('PresenceAbsence')}</h3>
          <ContactPageOutlinedIcon className='icon' />
        </div>
        <div className={props.curentPage == "addStudent" ? "secstionActive" : "section" } onClick={navigateToAddUser}>
          <h3>{t('addStudent')}</h3>
          <GroupAddOutlinedIcon className='icon' />
        </div>
        <div className="section">
          <h3>{t('addTeacher')}</h3>
          <PersonAddAltOutlinedIcon className='icon' />
        </div>
      </div>
    </div>
  )
}

export default Sidebar;