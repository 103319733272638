import React, { PureComponent } from 'react';
import './student.scss';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import DualCircularProgress from '../progressBar/CircularProgress';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'طلاب',
        uv: 90,
        fill: '#8884d8',
    },
    {
        name: 'طالبات',
        uv: 5,
        fill: '#83a6ed',
    },
];

const style = {
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
};


function Students() {

    const { t } = useTranslation();
    return (
        <div className='students'>
            <h3>{t("students")}</h3>
            <div className="chart">
                <ResponsiveContainer width="100%" height="100%">
                    <RadialBarChart cx="50%" cy="50%" innerRadius="45%" outerRadius="100%" barSize={30} data={data}>
                        <RadialBar
                            minAngle={43}
                            label={{ position: 'insideStart', fill: '#fff' }}
                            background
                            clockWise
                            dataKey="uv"
                            
                        />
                        {/* <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} /> */}
                    </RadialBarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default Students;