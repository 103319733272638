import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAeUEAFgDTR6SgVWtgl0t9mzrUtDR3uGBo",
    authDomain: "erido-school.firebaseapp.com",
    projectId: "erido-school",
    storageBucket: "erido-school.appspot.com",
    messagingSenderId: "664973980296",
    appId: "1:664973980296:web:29c35fd4c3e0e829ffe53d",
    measurementId: "G-B1KKWWKSDX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);